<template>
  <input type="text"
         :placeholder="placeholder"
         ref="input"
         @input="onInput"
         @focus="onInput"
         v-model="phone">
</template>

<script>
  export default {
    name: 'PhoneInput',
    props: {
      value: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: '0000-0000-0000'
      },
      onlyNumber: {
        type: Boolean,
        default: true
      },
      maxlength: {
        type: Number
      }
    },
    data () {
      return {
        value_old: '',
        // pos: 0,
        numberPadEvent: '',
      };
    },
    mounted: function () {
      this.$nextTick(() => {
        this.$emit('update:value', this.$options.filters.decodeInternationalPhoneNumber(this.value));
      });
    },
    computed: {
      phone: {
        get () {
          return this.$options.filters.decodeInternationalPhoneNumber(this.value);
        },
        set (value) {
          this.$emit('update:value', this.$options.filters.decodeInternationalPhoneNumber(value));
        }
      }
    },
    methods: {
      onInput(event) {
        if (event.target.value === '') return;
        if (event.target.value === this.value_old) return;
        this.value_old = event.target.value;
        this.phone = event.target.value;
        if (event.inputType === 'deleteContentBackward') {  // バックスペース
          // console.log('delete');
          this.numberPadEvent = 'deleteContentBackward';
        } else if (event.inputType === 'insertCompositionText') { // 全角入力
          return;
        } else if (event.inputType === 'insertText') { // 半角入力
          this.numberPadEvent = 'insertText';
        }
        this.formatted_call_to_number();
      },
      formatted_call_to_number () {
        if (this.maxlength) {
          this.phone = this.phone.slice(0, this.maxlength);
        }
        // if (this.numberPadEvent === 'deleteContentBackward') {
        //   if (this.value_old[this.value_old.length - 1] === '-') {
        //     this.$emit('input', this.phone.slice(0, this.phone.length - 1));
        //   }
        // } else if (this.numberPadEvent === 'insertText') {
        //   this.phone = this.removeNotNumber(this.phone);
        //   this.phone = this.$options.filters.decodeInternationalPhoneNumber(this.phone);
        //   if (this.phone !== this.value_old) {
        //     // this.pos -= (this.value_old.length - this.phone.length);
        //   }
        //   this.$emit('input', this.phone);
        // } else {
        //   // this.$emit('input', this.removeNotNumber(this.phone));
        // }
      },
      removeNotNumber (value) {
        if (!this.onlyNumber) return value;
        if (!value) return value;
        value = value.replace(/[０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
        value = value.replace(/[^0-9-]/g, '');
        this.$refs.input.value = value;
        return value;
      },
    }
  };
</script>

