<template>
  <section id="buy">
    <article id="wrap">
      <form action="buy_confirm.html">
        <AppHeader theme="green">
          <template v-slot:left>
            <a @click="$router.go(-1)"><img src="@/assets/img/icon_back.png"></a>
          </template>
          購入手続き
        </AppHeader>

        <section class="contents">
          <div class="contentInner">

            <div v-for="(item, index) in items" :key="item.id">
              <ul class="buy_digicon" v-if="isDigitalItem">
                <li v-for="photo in item.item_photos" :key="photo">
                  <img :src="photo | imageUrl" alt="デジコン画像">
                </li>
              </ul>
              <ul class="flex between align_c" v-if="!isDigitalItem">
                <li><CartItem v-bind="item"></CartItem></li>
                <li class="icon_area">
                  <a @click="removeCart(item)">
                    <img src="@/assets/img/buy/bt_trash.png" alt="ゴミ箱アイコン画像">
                  </a>
                </li>
              </ul>
              <hr class="item_hr mtb_20" v-if="index + 1 < items.length">
            </div>

            <div class="item_total">
              <div class="item_shipping">合計(税込)</div>
              <div class="item_price">{{ totalPrice | currency }}</div>
            </div><!-- .item_total -->
          </div><!--.contentInner-->
        </section><!--.contents-->

        <hr class="item_hr" v-if="!isDigitalItem">

        <section class="contents" v-if="!isDigitalItem">
          <div class="contentInner">
            <div class="ta_c txt_16 bold mb_10">お客様情報</div>

            <div class="error" v-if="$v.$dirty && $v.$error">
              入力内容に間違いがありました。項目をお確かめください。
            </div>
            <div class="mb_20">
              <label>お名前<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.last_name.$dirty && !$v.last_name.required">姓を入力してください</div>
              <div class="error" v-if="$v.last_name.$dirty && !$v.last_name.maxLength">姓を200文字以内で入力してください</div>
              <div class="error" v-if="$v.first_name.$dirty && !$v.first_name.required">名を入力してください</div>
              <div class="error" v-if="$v.first_name.$dirty && !$v.first_name.maxLength">名を200文字以内で入力してください</div>
              <div class="form_2col">
                <input type="text" placeholder="姓" :value="last_name" @input="$emit('update:last_name', $event.target.value)">
                <input type="text" placeholder="名" :value="first_name" @input="$emit('update:first_name', $event.target.value)">
              </div>
<!--              <div class="supplement">※お名前は全角でご入力ください。</div>-->
            </div>
            <div class="mb_20">
              <label>フリガナ<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.last_kana.$dirty && !$v.last_kana.required">セイを入力してください</div>
              <div class="error" v-if="$v.last_kana.$dirty && !$v.last_kana.maxLength">セイを200文字以内で入力してください</div>
              <div class="error" v-if="$v.first_kana.$dirty && !$v.first_kana.required">メイを入力してください</div>
              <div class="error" v-if="$v.first_kana.$dirty && !$v.first_kana.maxLength">メイを200文字以内で入力してください</div>
              <div class="form_2col">
                <input type="text" placeholder="セイ" :value="last_kana" @input="$emit('update:last_kana', $event.target.value)">
                <input type="text" placeholder="メイ" :value="first_kana" @input="$emit('update:first_kana', $event.target.value)">
              </div>
<!--              <div class="supplement">※フリガナは全角カタカナでご入力ください。</div>-->
            </div>

            <div class="mb_20">
              <label>メールアドレス<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.email.$dirty && !$v.email.required">入力してください</div>
              <div class="error" v-if="$v.email.$dirty && !$v.email.maxLength">200文字以内で入力してください</div>
              <div class="error" v-if="$v.email.$dirty && !$v.email.email">メールアドレスの書式が違います</div>
              <input type="text" placeholder="メールアドレスを入力してください" :value="email" @input="$emit('update:email', $event.target.value)">
<!--              <input type="text" placeholder="メールアドレス(確認)" v-model="email_conf">-->
<!--              <div class="supplement">※半角の英数字または、記号でご入力ください。</div>-->
            </div>

            <div class="mb_20">
              <label>電話番号<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.tel.$dirty && !$v.tel.required">入力してください</div>
              <div class="error" v-if="$v.tel.$dirty && !$v.tel.maxLength">200文字以内で入力してください</div>
              <phone-input :value="tel" @update:value="$emit('update:tel', $event)"></phone-input>
            </div>

            <div class="mb_20">
              <label>郵便番号<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.zip.$dirty && !$v.zip.required">入力してください</div>
              <div class="error" v-if="$v.zip.$dirty && !$v.zip.maxLength">200文字以内で入力してください</div>
              <zip-input id="edit_postcode" maxlength="8" size="8" placeholder="000-0000"
                         :value="zip"
                         @update:value="$emit('update:zip', $event)"></zip-input>
              <div class="bt_form_sub mt_5">
                <button type="button" @click="updateAddress">郵便番号から住所を検索する</button>
              </div>
            </div>

            <div class="mb_20">
              <label>都道府県<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.prefecture.$dirty && !$v.prefecture.required">選択してください</div>
              <div class="select-wrap">
                <select :value="prefecture" @input="$emit('update:prefecture', $event.target.value)">
                  <option value="都道府県" selected="">都道府県</option>
                  <option v-for="prefecture in prefectures" :key="prefecture">{{ prefecture }}</option>
                </select>
              </div><!-- .select-wrap -->
            </div>

            <div class="mb_20">
              <label >市区郡<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.city.$dirty && !$v.city.required">入力してください</div>
              <div class="error" v-if="$v.city.$dirty && !$v.city.maxLength">200文字以内で入力してください</div>
              <input type="text" placeholder="例：渋谷区" :value="city" @input="$emit('update:city', $event.target.value)">
            </div>

            <div class="mb_20">
              <label>町村・番地・建物名<span class="must">[必須]</span></label>
              <div class="error" v-if="$v.street.$dirty && !$v.street.required">入力してください</div>
              <div class="error" v-if="$v.street.$dirty && !$v.street.maxLength">200文字以内で入力してください</div>
              <input type="text" placeholder="例：上原2-28-3 ○○マンション201号室" :value="street" @input="$emit('update:street', $event.target.value)">
            </div>
          </div><!--.contentInner-->
        </section><!--.contents-->

        <hr class="item_hr">

        <template  v-if="haveBoughtDigitalContents">
          <section class="contents pb_150">
            このコンテンツは購入済みです
          </section>
        </template>
        <template  v-if="!haveBoughtDigitalContents">
          <section class="contents">
            <div class="contentInner">
              <div id="buy_rule">
                <div class="formCheckbox">
                  <div class="error" v-if="$v.$dirty && $v.accept.$error">チェックを入れてください</div>
                  <input type="checkbox" id="checkbox01" v-model="accept">
                  <label for="checkbox01" class="checkbox">みんなのせかいの
                    <RouterLink to="/privacy" target="_blank">プライバシーポリシー</RouterLink>
                    および
                    <RouterLink to="/law" target="_blank">特定商取引に関する法律に基づく表記および販売規約</RouterLink>
                    の内容を確認し、同意された方は、このボックスにチェックを入れてください。返品のポリシーはこちらからご確認ください。
                  </label>
                </div>
              </div>
            </div><!--.contentInner-->
          </section><!--.contents-->

          <hr class="item_hr">

          <section class="contents pb_150" v-if="!haveBoughtDigitalContents">
            <div class="contentInner">
              <div class="bt_form mb_30">
                <button type="button" @click="next">入力内容を確認する</button>
              </div>
            </div><!--.contentInner-->
          </section><!--.contents-->
        </template>

        <AppFooter></AppFooter>

      </form>
    </article>
  </section>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import CartItem from '@/components/CartItem';
import PhoneInput from '@/components/common/phone-input';
import ZipInput from '@/components/common/zip-input';
import {validationMixin} from 'vuelidate';
import {required, maxLength, email, sameAs} from 'vuelidate/lib/validators';
import axios from 'axios';
export default {
  components: { AppHeader, AppFooter, CartItem, PhoneInput, ZipInput},
  props: {
    user_id: Number,
    artist_id: Number,
    code: String,
    price: Number,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    email: String,
    zip: String,
    prefecture: String,
    city: String,
    street: String,
    building: String,
    tel: String,
    message: String,
    isDigitalItem: Boolean,
  },
  data () {
    return {
      email_conf: '',
      prefectures: [
        '北海道',
        '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
        '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
        '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県',
        '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
        '鳥取県', '島根県', '岡山県', '広島県', '山口県',
        '徳島県', '香川県', '愛媛県', '高知県',
        '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県',
        '沖縄県'
      ],
      accept: false,
      storedDigitalContents: [],
      haveBoughtDigitalContents: null,
    }
  },
  computed: {
    items () {
      return this.$store.state.cart;
    },
    totalPrice () {
      let price = 0;
      this.items.forEach(item => {
        if (this.isDigitalItem) {
          price += Number(item.price);
        } else {
          price += Number(item.price) * Number(item.cartValue);
        }
      })
      return price;
    },
  },
  mixins: [validationMixin],
  validations() {
    if (this.isDigitalItem) {
      return {
        accept: {
          sameAs: sameAs( () => true )
        },
      }
    } else {
      return {
        first_name: {
          required,
          maxLength: maxLength(200),
        },
        last_name: {
          required,
          maxLength: maxLength(200),
        },
        first_kana: {
          required,
          maxLength: maxLength(200),
        },
        last_kana: {
          required,
          maxLength: maxLength(200),
        },
        email: {
          required,
          maxLength: maxLength(200),
          email
        },
        tel: {
          required,
          maxLength: maxLength(200),
        },
        zip: {
          required,
          maxLength: maxLength(200),
        },
        prefecture: {
          required,
        },
        city: {
          required,
          maxLength: maxLength(200),
        },
        street: {
          required,
          maxLength: maxLength(200),
        },
        accept: {
          sameAs: sameAs( () => true )
        },
      }
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      // すでに購入済みか調べる
      const response = await this.$http.get('me/digital_contents');
      this.storedDigitalContents = response.data.map(v => v.url);
      this.haveBoughtDigitalContents = !!this.items.find(item => {
        return item.item_photos.find(item_photo => {
          return (this.storedDigitalContents.indexOf(item_photo) > -1);
        })
      })
    },
    next () {
      if (!this.invalid()) {
        this.$router.push({name: 'cart-confirm'});
      } else {
        this.scrollTop();
      }
    },
    invalid () {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    scrollTop() {
      const top = 0;
      window.scroll({top, behavior: 'smooth'});
    },
    async updateAddress () {
      if (!this.zip || this.zip.length < 7) {
        return;
      }
      axios.defaults.withCredentials = true;
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      const url = 'https://apis.postcode-jp.com/api/v4/postcodes/' + this.zip.replace('-', '');
      const res = await axios.get(
          url,
          {
            params: {
              apikey: '3255QD0K6c6GTesIaMSGFz7fE5NDA67TLQDvjgW',  // @todo 新しく取る
            },
          },
      );
      const address = res.data[0];
      this.$emit('update:prefecture', address.pref);
      this.$emit('update:city', address.city);
      this.$emit('update:street', address.town);
    },
    removeCart(item) {
      if (this.items.length === 1) {
        this.$swal('削除できません', '購入キャンセルするには戻ってください。', 'error');
      } else {
        this.$store.commit('removeCart', item);
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
  padding: 5px 10px;
}
</style>
