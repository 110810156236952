<template>
  <input type="tel" :placeholder="placeholder"
         class="form-control p-2 border-2 rounded border-gray-700 text-gray-700 leading-none focus:outline-none focus:shadow-outline"
         ref="input" @input="formatted_call_to_number" @focus="formatted_call_to_number" v-model="zip">
</template>

<script>
  export default {
    name: 'ZipInput',
    filters: {
      decodeZip: (zip) => {
        if (!zip) return '';
        zip = zip.replace(/[０-９]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        }).replace(/ー/g, '-').replace(/[^0-9+-]/g, '');
        if (zip.length >= 3) {
          if (zip[3] !== '-') {
            zip = zip.substr(0, 3) + '-' + zip.substr(3);
          }
        }
        return zip;
      }
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: '000-0000'
      },
      onlyNumber: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        value_old: ''
      };
    },
    mounted: function () {
      this.$nextTick(() => {
        this.$emit('input', this.$options.filters.decodeZip(this.value));
      });
    },
    computed: {
      zip: {
        get () {
          return this.$options.filters.decodeZip(this.value);
        },
        set (value) {
          this.$emit('input', this.$options.filters.decodeZip(value));
        }
      }
    },
    methods: {
      formatted_call_to_number (event) {
        if (event.target.value === '') return;
        if (event.target.value === this.value_old) return;
        if (event.inputType === 'deleteContentBackward') {
          if (this.value_old[this.value_old.length - 1] === '-') {
            this.$emit('input', event.target.value.slice(0, event.target.value.length - 1));
          }
        } else if (event.inputType === 'insertCompositionText') {
          // nothing
        } else {
          let value = event.target.value;
          value = this.removeNotNumber(value);
          this.value_old = value;
          this.$emit('update:value', this.$options.filters.decodeZip(value));
        }
      },
      removeNotNumber (value) {
        if (!this.onlyNumber) return value;
        if (!value) return value;
        value = value.replace(/[^0-9０-９ー-]/g, '');
        this.$refs.input.value = value;
        return value;
      },
      inputBlur () {
        // let ua = navigator.userAgent.toLowerCase();
        // let isiPhone = (ua.indexOf('iphone') > -1);
        // let isiPad = (ua.indexOf('ipad') > -1);
        // if (isiPhone || isiPad) {
        //   this.$nextTick(() => {
        //     document.querySelectorAll('.position-fixed').forEach(element => {
        //       element.style.position = 'fixed';
        //     })
        //   })
        // }
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
