<template>
  <div class="item">
    <ul class="col2">
      <li class="col2L">
        <div class="item_photo" v-if="item_photos && item_photos.length"><img :src="item_photos[0].image | imageUrl" alt="商品画像"></div>
        <div class="item_photo" v-else><img :src="'' | imageUrl" alt="商品画像"></div>
      </li>
      <li class="col2R">
        <div class="item_title">{{ name }}</div>
        <div class="item_text">
          {{ detail | truncate }}
        </div>
        <ul class="col2 mb_10">
          <li class="item_shipping">送料込</li>
          <li class="item_price">{{ price | currency }}<span>税込</span></li>
        </ul>
        <div class="item_volume">数量：{{ cartValue }}</div>
      </li>
    </ul><!--.col2-->
  </div><!-- .item -->
</template>

<script>

export default {
  props: {
    name: String,
    detail: String,
    price: Number,
    value: Number,
    item_photos: Array,
    cartValue: Number,
  },
}
</script>